<template>
  <!-- eslint-disable -->
  <v-container id="rfp-page" fluid tag="section" class="content-container">
    <v-row justify="start">
      <v-col cols="12">
        <base-material-card color="primary" class="px-5 py-3">
          <template v-slot:heading>
            <div class="display-2 font-weight-light">Call for Tenders</div>
            <div class="d-flex flex-row align-center justify-start subtitle-1 font-weight-light">
              <span>Tender Details</span>
            </div>
          </template>
          <!-- <div>
            <v-overlay
              :value="overlay"
              opacity="1"
              color="white"
              z-index="0"
              absolute="absolute"
            >
              <v-progress-circular indeterminate size="100" color="primary">
              </v-progress-circular>
            </v-overlay>
          </div> -->
          <v-card-text>
            <v-row class="d-flex flex-row align-start justify-space-between">
              <v-col cols="12" md="7">
                <v-row class="d-flex flex-row align-start justify-space-between">
                  <!-- country details details -->
                  <v-col cols="12" class="px-0">
                    <v-row class="px-0">
                      <v-col cols="10">
                        <h3 class="headline text-left">Open Tender Details</h3>
                      </v-col>
                      <v-col cols="2">
                        <v-btn icon class="circle-btn" @click="showDialog = true">
                          <font-awesome-icon :icon="['fas', 'pencil']" size="xs" />
                        </v-btn>
                      </v-col>
                      <v-dialog v-model="showDialog" scrollable max-width="900px">
                        <v-card>
                          <v-card-title class="pb-3 px-2 px-sm-6">
                            <v-row>
                              <v-col cols="8" lg="10" class="d-flex flex-column justify-center align-start py-3">
                                <h2 class="headline text-left text-break">
                                  Edit Open Tenders Details
                                </h2>
                              </v-col>
                              <v-col cols="4" lg="2" class="d-flex flex-column justify-center align-end py-0">
                                <v-btn icon color="secondary" @click="closeDialog">
                                  <v-icon>mdi-close</v-icon>
                                </v-btn>
                              </v-col>
                            </v-row>
                          </v-card-title>
                          <v-card-text>
                            <v-container class="px-0 pt-0">
                              <v-row class="mb-5">
                                <v-col cols="12" class="py-0">
                                  <h2 class="subHeading secondary--text text-left pb-3">
                                    Event Details
                                  </h2>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col cols="12" sm="6" class="py-0">
                                  <v-text-field class="my-0" label="Event Name*" required outlined v-model="eventName"
                                    :error-messages="errorEventName"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" class="py-0">
                                  <v-text-field class="my-0" label="No. of Delegates" v-model="noOfDelegates"
                                    :error-messages="errorNoOfDelegates" @keypress="[isNumber()]" outlined></v-text-field>
                                </v-col>
                                <v-col cols="12" md="12" class="py-0">
                                  <h6 class="text-left text-break">
                                    Preferred Area
                                  </h6>
                                </v-col>
                                <v-col cols="12" md="4" class="py-0">
                                  <v-select class="my-0" label="Preferred Region" outlined required v-model="region"
                                    :items="regionList" multiple :error-messages="errorRegion"
                                    placeholder="Region"></v-select>
                                </v-col>
                                <v-col cols="12" md="4" class="py-0">
                                  <v-autocomplete :items="countryList" v-model="country" :allow-overflow="false"
                                    color="primary" item-color="primary" item-text="name" label="Preferred Country"
                                    :error-messages="errorCountry" clear-icon="mdi-close" clearable outlined multiple
                                    required chips small-chips deletable-chips :disabled="!region.length">
                                    <template v-slot:selection="{
                                      attr,
                                      on,
                                      item,
                                      selected,
                                    }">
                                      <v-chip v-bind="attr" :input-value="selected" color="platinum"
                                        class="secondary--text" v-on="on">
                                        <span v-text="item" class="text-truncate" style="max-width: 80%"></span>
                                      </v-chip>
                                    </template>
                                  </v-autocomplete>
                                </v-col>
                                <v-col cols="12" md="4" class="py-0">
                                  <v-autocomplete :items="getFilteredCities" v-model="city" :allow-overflow="false"
                                    color="primary" item-color="primary" item-text="name" label="Preferred City"
                                    :error-messages="errorCity" clear-icon="mdi-close" clearable outlined multiple
                                    required chips small-chips deletable-chips>
                                    <template v-slot:selection="{
                                      attr,
                                      on,
                                      item,
                                      selected,
                                    }">
                                      <v-chip v-bind="attr" :input-value="selected" color="platinum"
                                        class="secondary--text" v-on="on">
                                        <span v-text="item" class="text-truncate" style="max-width: 80%"></span>
                                      </v-chip>
                                    </template>
                                  </v-autocomplete>
                                </v-col>
                                <v-col cols="12" md="12" class="py-0">
                                  <h6 class="text-left text-break">
                                    Preferred Time
                                  </h6>
                                </v-col>
                                <v-col cols="12" md="4" class="py-0">
                                  <v-menu v-model="dateMenu" :close-on-content-click="false" transition="scale-transition"
                                    offset-y>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-text-field label="Preferred Start Date" readonly outlined
                                        :placeholder="'Preferred Start Date'" :value="preferredDate" v-bind="attrs"
                                        v-on="on" height="55px" :error-messages="errorPreferredDate"
                                        @click="dateMenu = true"><template v-slot:append>
                                          <v-icon @click="dateMenu = true">mdi-calendar</v-icon>
                                        </template></v-text-field>
                                    </template>
                                    <v-date-picker class="datepicker" v-model="preferredDate" @input="dateMenu = false"
                                      :min="new Date().toISOString().substr(0, 10)
                                        " no-title></v-date-picker>
                                  </v-menu>
                                </v-col>
                                <v-col cols="12" md="4" class="py-0">
                                  <v-menu v-model="enddateMenu" :close-on-content-click="false"
                                    transition="scale-transition" offset-y>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-text-field label="Preferred End Date" readonly outlined
                                        :placeholder="'Preferred End Date'" :value="preferredEndDate" v-bind="attrs"
                                        v-on="on" height="55px" :error-messages="errorPreferredEndDate"
                                        @click="enddateMenu = true"><template v-slot:append>
                                          <v-icon @click="enddateMenu = true">mdi-calendar</v-icon>
                                        </template></v-text-field>
                                    </template>
                                    <v-date-picker class="datepicker" v-model="preferredEndDate" @input="enddateMenu = false"
                                      :min="preferredDate
                                        " no-title></v-date-picker>
                                  </v-menu>
                                </v-col>

                                <v-col cols="12" md="4" class="py-0">
                                  <v-autocomplete :items="months" v-model="prefMonths" :allow-overflow="false"
                                    color="primary" item-color="primary" item-text="name" label="Pref. Months"
                                    clear-icon="mdi-close" clearable outlined multiple required chips small-chips
                                    deletable-chips>
                                  </v-autocomplete>
                                </v-col>
                                <v-col cols="12" md="12" class="mb-2" style="margin-top: -40px;">
                                  <v-checkbox v-model="flexible" label="Flexible" color="primary"
                                    hide-details></v-checkbox>
                                </v-col>

                                <v-col cols="12" md="4" class="py-0">
                                  <v-row>
                                    <v-col cols="12">
                                      <v-text-field class="my-0" outlined v-model="prefYear" label="Pref. Years"
                                        @keydown.enter="addPrefYears"></v-text-field>
                                    </v-col>
                                  </v-row>
                                </v-col>
                                <v-col cols="12" md="12" class="py-0 justify-end"
                                  style="justify-content: right;margin-bottom: 15px;">
                                  <v-chip v-for="(item, index) in prefYears" :key="index" @click="removePrefYear(index)"
                                    color="platinum" style="margin-left: 2px;cursor: pointer;" class="secondary--text">
                                    {{ item }}
                                    <v-icon small dark>mdi-close</v-icon>
                                  </v-chip>
                                  <h5 class="text-left text-break" v-if="prefYears.length === 0">
                                    Please enter your preferred year and press Enter. You can add multiple years in this
                                    manner.
                                  </h5>
                                </v-col>
                                <v-col cols="12" md="6" class="py-0">
                                  <v-text-field class="my-0" label="No. of Days" outlined v-model="noOfDays"
                                    :error-messages="errorNoOfDays" @keypress="[isNumber()]"></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6" class="py-0">
                                  <v-text-field class="my-0" label="Company Website*" outlined required v-model="website"
                                    :error-messages="errorWebsite"></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6" class="py-0">
                                  <v-menu v-model="toMenu" :close-on-content-click="false" transition="scale-transition"
                                    offset-y>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-text-field label="Proposal Deadline" readonly outlined
                                        :placeholder="'Proposal Deadline'" :value="deadline" v-bind="attrs" v-on="on"
                                        height="55px" @click="toMenu = true"><template v-slot:append>
                                          <v-icon @click="toMenu = true">mdi-calendar</v-icon>
                                        </template></v-text-field>
                                    </template>
                                    <v-date-picker class="datepicker" v-model="deadline" @input="toMenu = false" :min="new Date().toISOString().substr(0, 10)
                                      " no-title></v-date-picker>
                                  </v-menu>
                                </v-col>
                                <v-col cols="12" md="6" class="py-0">
                                  <!-- <v-select
                                    class="my-0"
                                    label="Topics"
                                    outlined
                                    required
                                    v-model="topics"
                                    :items="topicList"
                                    :error-messages="errorTopic"
                                    multiple
                                  ></v-select> -->
                                  <v-autocomplete :items="topicList" v-model="topics" :allow-overflow="false"
                                    color="primary" item-color="primary" item-text="name" label="Topics"
                                    :error-messages="errorTopic" clear-icon="mdi-close" clearable outlined multiple
                                    required chips small-chips deletable-chips>
                                    <template v-slot:selection="{
                                      attr,
                                      on,
                                      item,
                                      selected,
                                    }">
                                      <v-chip v-bind="attr" :input-value="selected" color="platinum"
                                        class="secondary--text" v-on="on">
                                        <span v-text="item" class="text-truncate" style="max-width: 80%"></span>
                                      </v-chip>
                                    </template>
                                  </v-autocomplete>
                                </v-col>
                              </v-row>
                              <v-divider class="mb-2 mt-3"></v-divider>
                              <v-row class="mt-4 mb-2">
                                <v-col cols="12" class="py-0">
                                  <h2 class="subHeading secondary--text text-left pb-3">
                                    Contact Details
                                  </h2>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col cols="12" md="6" class="py-0">
                                  <v-text-field class="my-0" label="Member Name*" outlined required v-model="memberName"
                                    :error-messages="errorMemberName"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" class="py-0">
                                  <v-text-field class="my-0" @keypress="[isNumber()]" label="Mobile" outlined required
                                    v-model="mobile" :error-messages="errorMobile"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" class="py-0">
                                  <v-text-field class="my-0" label="Email*" outlined required v-model="email" :rules="[
                                    () =>
                                      !!checkEmailFn(email) ||
                                      'This email is invalid ',
                                  ]" :error-messages="errorEmail"></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6" class="py-0">
                                  <v-text-field class="my-0" label="Designation" outlined required
                                    v-model="memberDesignation" :error-messages="errorMemberDesignation"></v-text-field>
                                </v-col>
                              </v-row>
                              <v-divider class="mb-2 mt-3"></v-divider>
                              <v-row class="mt-4 mb-2">
                                <v-col cols="12" md="6" class="py-0 mb-5">
                                  <h2 class="subHeading secondary--text text-left pb-3 mb-2">
                                    Support Document/s
                                  </h2>
                                  <v-row>
                                    <v-col cols="12" class="py-0">
                                      <v-file-input label="Choose Document" placeholder="Choose Document" prepend-icon
                                        append-icon="mdi-paperclip" clear-icon="mdi-close" truncate-length="14" clearable
                                        outlined required accept="application/pdf" hide-details="auto" v-model="document"
                                        :error-messages="errorDocument"></v-file-input>
                                    </v-col>
                                  </v-row>
                                </v-col>
                                <v-col cols="12" md="6" class="py-0">
                                  <h2 class="subHeading secondary--text text-left pb-3 mb-2">
                                    Support Image
                                  </h2>
                                  <v-row>
                                    <v-col cols="12" class="py-0">
                                      <v-file-input label="Choose Image" placeholder="Choose Cover Image" prepend-icon
                                        append-icon="mdi-paperclip" clear-icon="mdi-close" truncate-length="14" clearable
                                        show-size outlined required accept="image/*" hide-details="auto"
                                        v-model="selectedImage" :error-messages="errorImage"></v-file-input>
                                    </v-col>
                                  </v-row>
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-card-text>
                          <v-card-actions class="px-6">
                            <v-row class="d-flex flex-column-reverse flex-sm-row align-center justify-space-between mx-0">
                              <v-col cols="12" sm="3" class="d-flex flex-column align-center align-sm-start px-0">
                                <v-btn class="px-8" color="secondary" @click="closeDialog" tile outlined depressed bottom>
                                  Cancel
                                </v-btn>
                              </v-col>
                              <v-col cols="12" sm="3" class="d-flex flex-column align-center align-sm-end px-0">
                                <v-btn class="px-8" color="secondary" @click="submitFn" tile depressed bottom right
                                  :loading="apiContactLoader">
                                  Submit
                                </v-btn>
                              </v-col>
                            </v-row>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-row>

                    <v-row class="px-0 mb-1">
                      <v-col cols="12" md="8" sm="8">
                        <h3 class="mainTitle text-left">{{ eventName }}</h3>
                      </v-col>
                      <v-col cols="12" md="4" sm="4" class="card-header">
                        <img :src="image" alt="Your Image" class="corner-image" v-if="image && image.length > 5" />
                        <img src="@/assets/placeholder.svg" alt="Your Image" class="corner-image" v-else />
                      </v-col>
                    </v-row>
                    <v-row class="px-0 mt-0">
                      <v-col cols="12">
                        <h3 class="subTitle text-left">Event Details</h3>
                      </v-col>
                    </v-row>
                    <!-- individual row for Name details -->
                    <v-row class="px-0">
                      <v-col cols="4">
                        <span class="subtitle-1 text-left">Name:</span>
                      </v-col>
                      <v-col cols="8">
                        <span class="subtitle-1 text-left">{{
                          eventName
                        }}</span>
                      </v-col>
                    </v-row>
                    <!-- end of individual row for Name details -->
                    <!-- individual row for No of Delegates details -->
                    <v-row class="px-0">
                      <v-col cols="4">
                        <span class="subtitle-1 text-left">No of Delegates:</span>
                      </v-col>
                      <v-col cols="8">
                        <span class="subtitle-1 text-left">{{
                          noOfDelegates ? noOfDelegates : "N/A"
                        }}</span>
                      </v-col>
                    </v-row>
                    <!-- end of individual row for No of Delegates details -->
                    <!-- individual row for Type of Preferred Date details -->
                    <v-row class="px-0">
                      <v-col cols="4">
                        <span class="subtitle-1 text-left">Preferred Dates:</span>
                      </v-col>
                      <v-col cols="8">
                        <span class="subtitle-1 text-left">
                          Start Date {{ preferredDate?preferredDate:'N/A' }}, End Date {{ preferredEndDate?preferredEndDate:'N/A' }} 
                        </span>
                      </v-col>
                    </v-row>
                    <v-row class="px-0">
                      <v-col cols="4">
                        <span class="subtitle-1 text-left">Pref. Months:</span>
                      </v-col>
                      <v-col cols="8">
                        <span class="subtitle-1 text-left">{{
                          preferredMonths !== '' ? preferredMonths : "N/A"
                        }}</span>
                      </v-col>
                    </v-row>
                    <v-row class="px-0">
                      <v-col cols="4">
                        <span class="subtitle-1 text-left">Pref. Years:</span>
                      </v-col>
                      <v-col cols="8">
                        <span class="subtitle-1 text-left">{{
                          preferredYears !== '' ? preferredYears : "N/A"
                        }}</span>
                      </v-col>
                    </v-row>
                    <v-row class="px-0">
                      <v-col cols="4">
                        <span class="subtitle-1 text-left">Flexible Dates:</span>
                      </v-col>
                      <v-col cols="8">
                        <span class="subtitle-1 text-left">{{
                          flexible ? ' Yes' : ' No'
                        }}</span>
                      </v-col>
                    </v-row>
                    <!-- end of individual row for Type of Preferred Date details -->
                    <!-- individual row for Proof of No. of Dates details -->
                    <v-row class="px-0">
                      <v-col cols="4">
                        <span class="subtitle-1 text-left">No. of Dates:</span>
                      </v-col>
                      <v-col cols="8">
                        <span class="subtitle-1 text-left">{{
                          noOfDays ? noOfDays : "N/A"
                        }}</span>
                      </v-col>
                    </v-row>
                    <!-- end of individual row for No. of Datesdetails -->
                    <!-- individual row for Country details -->
                    <v-row class="px-0">
                      <v-col cols="4">
                        <span class="subtitle-1 text-left">Pref. City:</span>
                      </v-col>
                      <v-col cols="8">
                        <span class="subtitle-1 text-left">{{
                          city ? city.join(', ') : "N/A"
                        }}</span>
                      </v-col>
                    </v-row>
                    <v-row class="px-0">
                      <v-col cols="4">
                        <span class="subtitle-1 text-left">Pref. Country:</span>
                      </v-col>
                      <v-col cols="8">
                        <span class="subtitle-1 text-left">{{
                          country ? country.join(', ') : "N/A"
                        }}</span>
                      </v-col>
                    </v-row>
                    <v-row class="px-0">
                      <v-col cols="4">
                        <span class="subtitle-1 text-left">Pref. Region:</span>
                      </v-col>
                      <v-col cols="8">
                        <span class="subtitle-1 text-left">{{
                          region !== '' ? region : "N/A"
                        }}</span>
                      </v-col>
                    </v-row>
                    <!-- end of individual row for Country details -->
                    <!-- individual row for Company Website -->
                    <v-row class="px-0">
                      <v-col cols="4">
                        <span class="subtitle-1 text-left">Company Website:</span>
                      </v-col>
                      <v-col cols="8">
                        <span class="subtitle-1 text-left">{{
                          website ? website : "N/A"
                        }}</span>
                      </v-col>
                    </v-row>
                    <!-- end of individual row for Company Website -->
                    <!-- individual row for Deadline -->
                    <v-row class="px-0">
                      <v-col cols="4">
                        <span class="subtitle-1 text-left">Proposal Deadline:</span>
                      </v-col>
                      <v-col cols="8">
                        <span class="subtitle-1 text-left">{{
                          deadline ? deadline : "N/A"
                        }}</span>
                      </v-col>
                    </v-row>
                  </v-col>
                  <hr class="hr-divider w-100" />
                  <!-- end of individual row for Deadline -->
                  <!-- country rep. details -->
                  <v-col cols="12" class="px-0 mt-5">
                    <v-row class="px-0">
                      <v-col cols="12">
                        <h3 class="subTitle text-left">Contact Details</h3>
                      </v-col>
                    </v-row>
                    <v-row class="px-0">
                      <v-col cols="4">
                        <span class="subtitle-1 text-left">Person Name:</span>
                      </v-col>
                      <v-col cols="8">
                        <span class="subtitle-1 text-left">{{
                          memberName ? memberName : "N/A"
                        }}</span>
                      </v-col>
                    </v-row>
                    <!-- end of individual row for Name details -->
                    <!-- individual row for Phone details -->
                    <v-row class="px-0">
                      <v-col cols="4">
                        <span class="subtitle-1 text-left">Mobile:</span>
                      </v-col>
                      <v-col cols="8">
                        <span class="subtitle-1 text-left">{{
                          mobile ? mobile : "N/A"
                        }}</span>
                      </v-col>
                    </v-row>
                    <!-- end of individual row for Phone details -->
                    <!-- individual row for Email details -->
                    <v-row class="px-0">
                      <v-col cols="4">
                        <span class="subtitle-1 text-left">Email:</span>
                      </v-col>
                      <v-col cols="8">
                        <v-tooltip bottom v-if="email">
                          <template v-slot:activator="{ on }">
                            <span class="subtitle-1 text-left" v-on="on">
                              <a :href="`mailto:${email}`">{{ email }}</a>
                            </span>
                          </template>
                          <span>
                            Send an email to
                            <b>{{ name }}</b> via
                            <b>
                              <i>{{ email }}</i>
                            </b>
                          </span>
                        </v-tooltip>
                        <span class="subtitle-1 text-left platinum--text text--darken-3" v-else>N/A</span>
                      </v-col>
                    </v-row>
                    <!-- end of individual row for Email details -->
                    <!-- individual row for Designation details -->
                    <v-row class="px-0">
                      <v-col cols="4">
                        <span class="subtitle-1 text-left">Designation:</span>
                      </v-col>
                      <v-col cols="8">
                        <span class="subtitle-1 text-left">{{
                          memberDesignation ? memberDesignation : "N/A"
                        }}</span>
                      </v-col>
                    </v-row>

                  </v-col>
                  <hr class="hr-divider w-100" />
                  <!-- country rep. details -->
                  <v-col cols="12" class="px-0 mt-5">
                    <v-row class="px-0">
                      <v-col cols="4">
                        <h3 class="subTitle text-left">Event Document/s:</h3>
                      </v-col>
                      <v-col cols="8">
                        <span class="subtitle-1 text-left mr-2">{{
                          document ? eventName + ".pdf" : "N/A"
                        }}</span>

                        <v-btn icon class="circle-btn">
                          <font-awesome-icon :icon="['fas', 'download']" size="xs"
                            @click="downloadFn(document, eventName)" />
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="5">
                <v-row class="d-flex flex-row align-start justify-space-between">
                  <v-col cols="12" class="px-0">
                    <v-row class="px-0">
                      <v-col cols="12">
                        <h3 class="headline text-left">Click Through List</h3>
                        <v-col cols="12" class="px-0">
                          <v-data-table :headers="headers" :items="CTRateList"></v-data-table>
                        </v-col>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Tenders from "../../../services/tender.service";
import DateFormatYYMMDD from "../../../mixin/DateFormatYYMMDD";
import CheckEmail from "../../../components/util/CheckEmail";
import isNumber from "../../../mixin/IsNumber";
export default {
  name: "CountrySubView",
  mixins: [isNumber],
  data() {
    return {
      isAPILoading: false,
      countryName: null,
      countrySubscriberId: null,
      website: null,
      address: null,
      city: [],
      country: [],
      region: [],
      eventName: null,
      noOfDelegates: null,
      preferredDate: null,
      preferredEndDate: null,
      preferredMonths: null,
      preferredYears: null,
      months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      prefYears: [],
      prefMonths: [],
      flexible: false,
      prefYear: null,
      dateMenu: false,
      enddateMenu: false,
      noOfDays: null,
      deadline: null,
      memberName: null,
      document: null,
      email: null,
      name: null,
      mobile: null,
      memberDesignation: null,
      selectedImage: null,
      topics: [],
      fromMenu: null,
      toMenu: null,
      errorEventName: null,
      errorNoOfDelegates: null,
      errorCountry: null,
      errorCity: null,
      errorRegion: null,
      errorPreferredDate: null,
      errorPreferredEndDate: null,
      errorNoOfDays: null,
      errorWebsite: null,
      errorDeadline: null,
      errorTopic: null,
      errorMemberName: null,
      errorMemberDesignation: null,
      errorMobile: null,
      errorDocument: null,
      errorEmail: null,
      errorImage: null,
      organiserId: null,
      tenderId: null,
      image: null,
      apiContactLoader: false,
      min: new Date(Date.now() - 315569260000).toISOString().substr(0, 10),
      headers: [
        {
          text: "Country/City",
          value: "countryOrCity",
          class: "primary--text title",
        },
        {
          text: "Name",
          value: "name",
          class: "primary--text title",
        },
      ],
      CTRateList: [],
      showDialog: false,
      countryList: [],
      cityList: [],
      regionList: ['Europe', 'Asia', 'North-America', 'Middle-East'],
      topicList: [
        "Sustainability",
        "Biology",
        "Construction",
        "Creative",
        "Ecology & Environment",
        "Economics",
      ],
      staticErrorMessages: {
        eventName: {
          isEmpty: "Event Name should not be empty",
        },
        noOfDelegates: {
          isEmpty: "No. of Delegates should not be empty",
        },
        email: {
          isEmpty: "Email should not be empty",
          invalidEmail: "Invalid Email address",
        },
        country: {
          isEmpty: "Preferred country should not be empty",
        },
        city: {
          isEmpty: "Preferred city should not be empty",
        },
        region: {
          isEmpty: 'Preferred region should not be empty'
        },
        preferredDate: {
          isEmpty: "Preferred Start Date should not be empty",
        },
        preferredEndDate: {
          isEmpty: "Preferred End Date should not be empty",
        },
        noOfDays: {
          isEmpty: "No. of Days should not be empty",
        },
        website: {
          isEmpty: "Company Website should not be empty",
        },
        deadline: {
          isEmpty: "Proposal Deadline should not be empty",
        },
        topics: {
          isEmpty: "Topics should not be empty",
        },
        memberName: {
          isEmpty: "Member Name should not be empty",
        },
        memberDesignation: {
          isEmpty: "Member Designation should not be empty",
        },
        mobile: {
          isEmpty: "Mobile should not be empty",
        },
        document: {
          isEmpty: "Document should not be empty",
        },
        selectedImage: {
          isEmpty: "Image should not be empty",
        },
      },
    };
  },
  async mounted() {
    this.initFn(this.$route.params.id);
    this.getDetails(this.$route.params.id);
    this.getCountriesAndCities();
  },
  computed: {
    getFilteredCities() {
      if (this.country && this.country.length > 0) {
        const selectedCities = this.cityList
          .filter((item) => this.country.includes(item.country))
          .map((item) => item.city);
        return Array.from(new Set(selectedCities));
      } else {
        return [];
      }
    },
  },
  watch: {
    eventName() {
      this.errorEventName = null;
    },
    noOfDelegates() {
      this.errorNoOfDelegates = null;
    },
    country() {
      this.errorCountry = null;
    },
    city() {
      this.errorCity = null;
    },
    region() {
      this.errorRegion = null;
    },
    preferredDate() {
      this.errorPreferredDate = null;
    },
    preferredEndDate() {
      this.errorPreferredEndDate = null;
    },
    noOfDays() {
      this.errorNoOfDays = null;
    },
    website() {
      this.errorWebsite = null;
    },
    deadline() {
      this.errorDeadline = null;
    },
    topics() {
      this.errorTopic = null;
    },
    memberName() {
      this.errorMemberName = null;
    },
    memberDesignation() {
      this.errorMemberDesignation = null;
    },
    mobile() {
      this.errorMobile = null;
    },
    email() {
      this.errorEmail = null;
    },
    document() {
      this.errorDocument = null;
    },
    selectedImage() {
      this.errorImage = null;
    },
    showDialog(val) {
      // if (val) {
      //   let x = [];
      //   for (let index = 0; index < this.region.split(',').length; index++) {
      //     x.push(this.region.split(',')[index]);
      //   }
      //   this.region = x;
      // } else {
      //   this.region = this.region.join(', ');
      // }
      if (val) {
      this.region = this.region ? this.region.split(',').map(r => r.trim()) : [];
    } else {
      this.region = Array.isArray(this.region) ? this.region.join(', ') : this.region;
    }
    }
  },
  methods: {
    removePrefYear(index) {
      this.prefYears.splice(index, 1);
    },
    addPrefYears() {
      if (!isNaN(this.prefYear) && this.prefYear.trim().length === 4) {
        if (this.prefYears.includes(this.prefYear)) {
          this.prefYear = '';
          let snackbarPayload = {
            snackBarText: 'This year has already been selected. Please choose a different year.',
            snackBarColor: 'secondary'
          }
          this.$store.dispatch("setSnackbarMessage", snackbarPayload);
          return;
        }
        if (this.prefYear.trim() !== '') {
          this.prefYears.push(this.prefYear.trim());
          this.prefYear = '';
        }
      } else {
        let snackbarPayload = {
          snackBarText: 'Preferred year must be a four-digit number and cannot contain any symbols or characters other than digits (0-9)',
          snackBarColor: 'error'
        }
        this.$store.dispatch("setSnackbarMessage", snackbarPayload);
      }
    },
    saveChanges() {
      this.showDialog = false;
    },
    closeDialog() {
      this.showDialog = false;
    },
    checkEmailFn(emailAddress) {
      return CheckEmail(emailAddress);
    },
    async submitFn() {
      console.log("async submit");
      if (
        this.eventName &&
        // this.noOfDelegates &&
        this.country &&
        this.city &&
        this.preferredDate &&
        this.preferredEndDate &&
        // this.noOfDays &&
        this.website &&
        this.deadline &&
        // this.topics &&
        this.memberName &&
        // this.memberDesignation &&
        // this.mobile &&
        this.email &&
        this.document
        // && this.selectedImage
      ) {
        console.log("Please");
        this.apiContactLoader = true;

        const toBase64 = (file) =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
          });
        try {
          let payload = {
            img: this.selectedImage,
          };
          if (this.selectedImage instanceof Blob) {
            const currentFileName = this.image
            ? this.image.split('/').pop()
            : `${JSON.parse(localStorage.getItem('admin')).admin._id +"-"+ Date.now() +"-"+ this.eventName.replace(/[^a-zA-Z]/g, '').toLowerCase()}.jpg`;

            const payloadImg = {
              // Path: `${this.organiserId + this.eventName.replace(/[^a-zA-Z]/g, '').toLowerCase()}.jpg`,
              Path: this.organiserId ?
              `${this.organiserId + this.eventName.replace(/[^a-zA-Z]/g, '').toLowerCase()}.jpg` :
              currentFileName,
              Base64: await toBase64(this.selectedImage),
            };
            console.log('767');
            const imgResponse = await Tenders.imageUpload(payloadImg);
            payload.img = imgResponse.data.content;
          }
          console.log('772');
          var formData = new FormData();
          formData.append("file", this.document);
          let payload2;
          if (this.document) {
            await Tenders.uploadFile(formData).then((res) => {
              payload2 = {
                file: res.data.details,
              };
            });
          } else {
            payload2 = null;
          }
          console.log('785');
          let payload3 = {
            EeventName: this.eventName,
            noOFDelegates: this.noOfDelegates,
            country: this.country,
            city: this.city,
            region: this.region,
            preferredDate: this.preferredDate + ' To ' + this.preferredEndDate,
            prefMonths: this.prefMonths,
            prefYears: this.prefYears,
            flexible: this.flexible,
            noOFDays: this.noOfDays,
            companyWeb: this.website,
            proposalDedline: this.deadline,
            topics: this.topics,
            contactName: this.memberName,
            contactMobile: this.mobile,
            contactEmail: this.email,
            contactDesignation: this.memberDesignation,
            supportFile: payload2 ? payload2.file : "",
            supportImage: payload.img,
            organiserId: this.organiserId,
            _id: this.tenderId,
          };
          console.log('806');
          Tenders.updateCallForTenderById(payload3).then((res) => {
            console.log(res);
            if (res.status == 200) {
              let payload = {
                snackBarText: "Call For Tender has been updated successfully",
                snackBarColor: "success",
              };
              this.$store.dispatch("notify/setSnackbarMessage", payload);
              this.apiContactLoader = false;
              this.showDialog = false;
              this.initFn(this.$route.params.id);
            } else {
              let payload = {
                snackBarText: "You cannot update Call For Tender",
                snackBarColor: "secondary",
              };
              this.$store.dispatch("notify/setSnackbarMessage", payload);
              this.apiContactLoader = false;
            }
          });
          console.log('827');
        } catch (error) {
          console.log("Error:", error);
          this.apiContactLoader = false;
        }
      } else {
        if (!this.eventName) {
          this.errorEventName = this.staticErrorMessages.eventName.isEmpty;
        }
        if (!this.noOfDelegates) {
          // this.errorNoOfDelegates =
          //   this.staticErrorMessages.noOfDelegates.isEmpty;
        }
        if (!this.country) {
          this.errorCountry = this.staticErrorMessages.country.isEmpty;
        }
        if (!this.city) {
          this.errorCity = this.staticErrorMessages.city.isEmpty;
        }
        if (!this.preferredDate) {
          this.errorPreferredDate =
            this.staticErrorMessages.preferredDate.isEmpty;
        }
        if (!this.preferredEndDate) {
          this.errorPreferredEndDate =
            this.staticErrorMessages.preferredEndDate.isEmpty;
        }
        if (!this.noOfDays) {
          // this.errorNoOfDays = this.staticErrorMessages.noOfDays.isEmpty;
        }
        if (!this.website) {
          this.errorWebsite = this.staticErrorMessages.website.isEmpty;
        }
        if (!this.deadline) {
          this.errorDeadline = this.staticErrorMessages.deadline.isEmpty;
        }
        if (!this.topics) {
          // this.errorTopic = this.staticErrorMessages.topics.isEmpty;
        }
        if (!this.memberName) {
          this.errorMemberName = this.staticErrorMessages.memberName.isEmpty;
        }
        if (!this.mobile) {
          // this.errorMobile = this.staticErrorMessages.mobile.isEmpty;
        }
        if (!this.email) {
          this.errorEmail = this.staticErrorMessages.email.isEmpty;
        }
        if (!this.memberDesignation) {
          // this.errorMemberDesignation = this.staticErrorMessages.memberDesignation.isEmpty;
        }
        if (!this.document) {
          this.errorDocument = this.staticErrorMessages.document.isEmpty;
        }
        if (!this.selectedImage) {
          // this.errorImage = this.staticErrorMessages.selectedImage.isEmpty;
        }
      }
    },
    initFn(userId) {
      Tenders.getTenderUser(userId).then((res) => {
        const details = res.data;
        
        this.prefMonths = details.prefMonths || [];
        this.prefYears = details.prefYears || [];

        this.eventName = details.EeventName;
        this.noOfDelegates = details.noOFDelegates;
        this.preferredDate = details.preferredDate.split('To')[0]?DateFormatYYMMDD(details.preferredDate.split('To')[0]):null;
        this.preferredEndDate = details.preferredDate.split('To')[1]?DateFormatYYMMDD(details.preferredDate.split('To')[1]):null;
        this.preferredMonths = details.prefMonths ? details.prefMonths.join(', ') : '';
        this.preferredYears = details.prefYears ? details.prefYears.join(', ') : '';
        this.flexible = details.flexible;
        this.noOfDays = details.noOFDays;
        this.country = details.country;
        this.city = details.city;
        this.region = details.region ? details.region.join(', ') : '';
        this.website = details.companyWeb;
        this.deadline = DateFormatYYMMDD(details.proposalDedline);
        this.topics = details.topics;
        this.memberName = details.contactName;
        this.memberDesignation = details.contactDesignation;
        this.mobile = details.contactMobile;
        this.email = details.contactEmail;
        this.organiserId = details.organiserId;
        this.tenderId = details._id;
        this.image = details.supportImage;
        const documentUrl = details.supportFile;

        const imageUrl = details.supportImage;

        if (imageUrl) {
          const originalFileName = imageUrl.split('/').pop();
          fetch(imageUrl)
            .then((response) => response.blob())
            .then((blob) => {
              // const file = new File([blob], `${userId}.jpg`, {
              //   type: "image/jpeg",
              // });
              const file = new File([blob], originalFileName, {
                type: "image/jpeg",
              });

              this.selectedImage = file;
              // this.displayedImage = imageUrl
            })
            .catch((error) => {
              console.error("Error fetching image:", error);
            });
        } else {
          this.selectedImage = null;
          // this.displayedImage = null
        }
        if (documentUrl) {
          fetch(documentUrl)
            .then((response) => response.blob())
            .then((blob) => {
              const file = new File([blob], `${this.eventName}.pdf`, {
                type: "application/pdf",
              });
              this.document = file;
            })
            .catch((error) => {
              console.error("Error fetching PDF:", error);
            });
        } else {
          this.document = null;
        }
      });

      console.log(this.image);
    },
    async downloadFn(link, name) {
      try {
        const pdfUrl = link;
        const response = await fetch(pdfUrl);
        const blob = await response.blob();

        const downloadLink = document.createElement("a");
        downloadLink.href = window.URL.createObjectURL(blob);
        (downloadLink.download = `${name}.pdf`), downloadLink.click();
      } catch (error) {
        console.error("Error downloading PDF:", error);
      }
    },
    getDetails(tender) {
      Tenders.getallCTRates(tender).then((res) => {
        const details = res.data;
        this.CTRateList = details.map((x) => {
          return {
            name: x.name,
            countryOrCity: x.countryOrCity,
          };
        });
      });
    },
    capitalize(s) {
      if (typeof s !== "string") return "";
      return s.charAt(0).toUpperCase() + s.slice(1);
    },
    getCountriesAndCities() {
      Tenders.getAllCityV4().then((res) => {
        const details = res.data.data;
        if (details) {
          this.cityList = details.map((item) => ({
            country: this.capitalize(item.country),
            city: this.capitalize(item.city),
          }));

          const countries = new Set(this.cityList.map((item) => item.country));
          this.countryList = Array.from(countries);

          console.log("Countries:", this.countryList);
          console.log("Cities:", this.cityList);
        }
      });
    },
  },
};
</script>
<style scoped>
.mainTitle {
  font-size: 22px;
}

.subTitle {
  font-size: 18px;
}

.circle-btn.v-btn {
  width: 24px;
  height: 24px;
  min-width: 24px;
  border-radius: 50%;
  background-color: #313638 !important;
  color: white;
}

.test {
  background-color: #313638 !important;
}

.subHeading {
  font-size: 18px;
  font-weight: 500;
  font-family: "TTHoves-Medium", "Open Sans", sans-serif !important;
}

.main-title {
  font-size: 32px;
  font-family: "TTHoves-medium", "Open Sans", sans-serif !important;
  font-weight: 500;
}

.hr-divider {
  background-color: #e8e9eb;
  height: 1px;
  border: none;
}

.content-container {
  position: relative;
}

.v-data-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.card-header {
  position: relative;
  padding: 10px;
  /* Optional padding */
}

.corner-image {
  position: absolute;
  top: 20px;
  border-radius: 8px;
  width: 145px;
  height: 145px;
}

.datepicker {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

@media (max-width: 600px) {
  .corner-image {
    position: static;
  }
}
</style>
